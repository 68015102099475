.register {
  background-repeat: no-repeat;
  background-image: radial-gradient(
    circle at 50% 14em,
    #313264 0%,
    #00023b 60%,
    #00023b 100%
  );
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 1px;
  min-height: 100vh;

  .MuiCardHeader-root {
    flex-direction: column;

    .MuiCardHeader-avatar {
      margin-right: 0;
    }

    .MuiCardHeader-content {
      display: none;
    }
  }

  .MuiCardActions-root {
    > div,
    .MuiLoadingButton-root {
      width: 100%;
    }
  }
}
