@import './vendor.scss';

body {
  margin: 0 0;
}

// .list-page,
// .edit-page {
//   // Provide space for the floating action button
//   @media (max-width: 599.95px) {
//     margin-bottom: 100px;
//   }
// }

@keyframes dotBlink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 250ms, transform 250ms;
}
